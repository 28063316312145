<template>
  <div>
  <el-dialog :append-to-body="true" :visible.sync="dialog" v-if="dialog" :close-on-click-modal="false" :before-close="cancel" :title="isAdd ? '新增' : '编辑'" width="600px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="社区" prop="siteId">
        <el-select class="align-top" clearable v-model="form.siteId" filterable placeholder="选择站点" style="width: 100%;" @change="siteChange">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :value="item.id"
            :label="item.id+'-'+item.siteName"
          >
          </el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="收费功能" prop="status">
         <el-row :gutter="24">
           <el-col :span="10">
              <el-form-item>
                <el-radio v-model="form.status" :label="1">启用</el-radio>
                <el-radio v-model="form.status" :label="0">禁用</el-radio>
              </el-form-item>
           </el-col>
           <el-col :span="13" style="display: flex;justify-content: flex-end;">
                <el-form-item label="免费体验" prop="freePeriod">
                  <el-input v-model="form.freePeriod" style="width:130px;margin-right:10px;"></el-input>天
                </el-form-item>
           </el-col>
         </el-row>
       </el-form-item>
      <el-form-item label="收费模式">
        <el-row :gutter="24" style="margin-bottom: 15px;">
          <el-col :span="6">
              <el-radio v-model="radio" :label="1">按月收费</el-radio>
          </el-col>
          <el-col :span="6" :offset="11">
            <el-button @click="addCharge" type="primary">添加</el-button>
           </el-col>
            <div>
                <el-checkbox :checked="true" :disabled="true"></el-checkbox>
                <span>免费</span>
            </div>
          <div>
            <el-col :span="12" style="padding:0;">
              <el-checkbox :checked="customStatus ==1 ? true:false" @change="customChange($event,form.ruleFees)"></el-checkbox>
              <span>自定义价格</span>
            </el-col>
            <el-col :span="9" :offset="3" style="color: #0066CC;" >
              <a @click="customlimitClick(customIndexList)" onmouseover="this.style.cursor='hand'">信号限制</a>
            </el-col>
            <el-col :span="24">
              <span v-for="(subLimit,subitem) in customIndexList" :key="subitem">
                <span>{{subLimit.indexName}}{{subLimit.indexLimit}}{{subLimit.indexValue}}</span>；
              </span>
            </el-col>
          </div>
        </el-row>
        <el-row class="el-bottom" :gutter="24">
          <div v-for="(item,index) in ruleFeesArr" :key="index" :span="24">
            <el-row>
              <!-- <div v-if="item.price === 0">
                <el-checkbox :checked="true" :disabled="true"></el-checkbox>
                <span>免费</span>
              </div>
              <div v-if="!!item.price && item.price == -1">
                <el-col :span="12">
                   <el-checkbox :checked="item.status == 1?true:false" @change="customChange($event,form.ruleFees,index)"></el-checkbox>
                   <span>自定义价格</span>
                </el-col>
                <el-col :span="9" :offset="3" style="color: #0066CC;" >
                  <a v-if="form.status ==1" @click="limitBoxClick(item,index,form.ruleFees,)" onmouseover="this.style.cursor='hand'">信号限制</a>
                </el-col>
                <el-col :span="24">
                  <span v-for="(subLimit,subitem) in item.indexList" :key="subitem">
                    <span>{{subLimit.indexName}}{{subLimit.indexLimit}}{{subLimit.indexValue}}</span>；
                  </span>
                </el-col>
              </div> -->
              <div>
                <el-col :span="12" style="display: flex;">
                  <el-checkbox :checked="item.status == 1" style="margin-right:5px;" @change="checkChange($event,ruleFeesArr,index)"></el-checkbox>
                  <span>
                    <el-form-item>
                      <el-input class="checkInput" v-model.number="item.price" placeholder="请输入价格(大于零整数)" type="number" @mousewheel.native.prevent onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>$/月
                      <div class="el-form-item__error" v-if="item.status ==1 && item.price<=0">请输入大于零的正数</div>
                    </el-form-item>
                  </span>
                </el-col>
                <el-col :span="9" :offset="3" style="color: #0066CC;" >
                  <a @click="limitBoxClick(item,index)" onmouseover="this.style.cursor='hand'">信号限制</a>
                  <el-button size="mini" v-if="!item.id" @click="removelimitBox(item,index,ruleFeesArr,form.ruleFees)">移除</el-button>
                </el-col>
                <el-col :span="24">
                  <span v-for="(subLimit,subitem) in item.indexList" :key="subitem">
                    <span>{{subLimit.indexName}}{{subLimit.indexLimit}}{{subLimit.indexValue}}</span>；
                  </span>
                </el-col>
              </div>
            </el-row>
          </div>
        </el-row>
      </el-form-item>
        <el-form-item label="账号限制" >
          <el-checkbox :checked="platformSignalStatusFlag" @change="SignalCheckChange($event)">交易员账号限制</el-checkbox>
          <el-form-item class="LimitBox">
            <el-select v-model="platformSignalVaule" multiple placeholder="请选择" style="width: 100%;" @change="brokerSignal">
                <el-option
                  v-for="item in limitOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
                </el-option>
            </el-select>
            <div class="el-form-item__error" v-if="!platformSignalFlag">请选择交易员账号限制</div>
          </el-form-item>
          <el-checkbox :checked="platformCopyStatusFlag" @change="CopyCheckChange($event)">跟随者账号限制</el-checkbox>
          <el-form-item class="LimitBox">
            <el-select v-model="platformCopyVaule" multiple placeholder="请选择" style="width: 100%;" @change="brokerCopy">
                <el-option
                  v-for="item in limitOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
                </el-option>
            </el-select>
            <div class="el-form-item__error" v-if="!platformCopyFlag">请选择跟随者账号限制</div>
          </el-form-item>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
    <div class="modifyDesc" v-if="!isAdd">
      <div class="title">修改记录</div>
      <el-table v-loading="loading" :data="form.ruleHists" size="small" stripe style="width: 100%;height:400px;height: 400px;overflow: auto;">
        <el-table-column prop="id" label="修改内容">
          <template slot-scope="scope">
            {{scope.row.record}}
          </template>
        </el-table-column>
        <el-table-column prop="updateBy" align="center" label="修改人" width="100"/>
        <el-table-column prop="newTime" label="修改时间" width="100"/>
    </el-table>
    </div>
    <!-- <signaldialog ref="signalfrom"/> -->
  </el-dialog>

    <el-dialog
      width="500px"
      top="25vh"
      class="innerDialog"
      title="信号限制"
      :visible.sync="limitVisible"
      append-to-body>
      <div class="LimitBottom">
        <el-form :model="limitFrom" ref="limitFrom" size="small" label-position="right">
          <el-row :gutter="24" v-for="(item,index) in limitFrom.limitData" :key="index">
            <el-col :span="12">
                <el-form-item :label="item.indexName" style="text-align: right;" :prop="`limitData[${index}].indexLimit`" :rules="{ required: true, message: '必选', trigger: 'blur' }">
                <el-select class="align-top" v-model="item.indexLimit" filterable placeholder="" style="width: 100px" @change="selectChange($event,item)">
                    <el-option
                    v-for="item in Symbol"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label">
                    </el-option>
                </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :prop="`limitData[${index}].indexValue`" :rules="{ required: true, message: '必填，输入正数', trigger: 'blur' }">
                <el-input v-model.number="item.indexValue" type="number" style="width: 150px;margin-right: 10px;" @input="indexValueChange($event)" @mousewheel.native.prevent></el-input>{{item.displaySuffix}}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="limitVisible = false">取消</el-button>
          <el-button :loading="loading" type="primary" @click="limitConfirm">确认</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import {findLimit,edit, add,getCate} from '@/api/copy/srCommunityRule.js'
// import signaldialog from './signaldialog'
import { uniquesArr } from '@/utils/index'
import { validatpositiveInt,validatohundred,validatgreater } from '@/utils/validate'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites:{
      type: Array,
      required: true
    },
  },
  // components: { signaldialog },
  data() {
     var checkLowest = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('免费订阅天数不能为空'));
        }
        setTimeout(() => {
          if (!validatpositiveInt(value)) {
            callback(new Error('请输入正整数'));
          } else {
            callback();
          }
        }, 500);
      };
      var checkPrice = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('价格不能为空，请输入大于零正数'));
        }
        setTimeout(() => {
          if (!validatgreater(value)) {
            callback(new Error('请输入正整数'));
          } else {
            callback();
          }
        }, 500);
      };
      var checkPositive = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('免费订阅天数不能为空'));
        }
        setTimeout(() => {
          if (!validatpositiveInt(value)) {
            callback(new Error('请输入大于零的整数'));
          } else {
            callback();
          }
        }, 500);
      };
      var checkHundred = (rule, value, callback) => {
        setTimeout(() => {
          if (!validatohundred(value)) {
            callback(new Error('请输入0到100之间的正数'));
          } else {
            callback();
          }
        }, 500);
    };
    return {
      loading: false,
      dialog: false,
      form: {
        freePeriod: '',
        platformCopy: '',
        platformSignal: "",
        platformSignalStatus: 0,
        platformCopyStatus: 0,
        ruleFees: [
          {
            price: 0,
            model: 1,
            currency: 'USD',
            status: 1,
            indexList: [],
          },
          {
            price: -1,
            model: 1,
            currency: 'USD',
            status: 0,
            indexList: [{indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
            {indexId:7,indexName:"最大回撤比例",indexLimit:">=",indexValue:0,},
            {indexId:8,indexName:"收益率",indexLimit:">=",indexValue:0,},
            {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
          },
        ],
        ruleHists: [],
        siteId: null,
        sitePriceList: null,
        status: 1
      },
      radio:1,
      platformRadio: '',
      rules:{
        price: [{ required: true,validator: checkPrice, trigger: 'blur' }],
        status: [{ required: true, message: "状态为必填", trigger: "blur" }],
        siteId: [{ required: true, message: "所属站点不能为空", trigger: "blur" }],
        isMaster:[{ required: true, message: "选择收费功能", trigger: "blur" }],
        freePeriod: [{ required: true,validator: checkLowest, trigger: 'blur' }],
        limit: [{ required: true, message: "必填，输入正数", trigger: "blur" }],
        // platformSignalVaule:[{required: true, message: "请选择交易员账号限制", trigger: "blur" }],
        // platformCopyVaule:[{required: true, message: "请选择跟随者账号限制", trigger: "blur" }]
      },
      limitOptions: [],
      limitVaule: '',
      brokerList: '',
      brokerCheck: -1,
      limitIndex:Number,
      Symbol:[
          {value: '1',label: '<'},
          {value: '2',label: '>'},
          {value: '3',label: '='},
          {value: '4',label: '<='},
          {value: '5',label: '>='},
          {value: '6',label: '!='},
      ],
      limitVisible: false,
      loading: false,
      limitFrom: {},
      limitRules: {
        indexValue: [{validator: checkHundred, trigger: 'blur' }],
      },
      ruleFeesListOne: [],
      // 选择框
      platformSignalVaule: [],
      platformCopyVaule: [],
      // 移除的价格ids
      ruleFeesArr: [],
      customStatus: 0,
      customIndexList:[],
      checkPriceFlag: false,
    }
  },
  watch:{
    'form': {
        handler(val,oldVal){
          if(this.form.platformSignal) {
             this.platformSignalVaule = this.form.platformSignal.split(',')
          }
          if(this.form.platformCopy) {
             this.platformCopyVaule = this.form.platformCopy.split(',')
          }
        },
        deep: true
    },
    'ruleFeesArr': {
        handler(val){
          var arr = this.form.ruleFees.concat(val);
          this.form.ruleFees = this.uniquesArr(arr);
        },
        deep: true
    },
  },
  computed:{
    platformSignalFlag(){
      let flag = true;
      if(this.form.platformSignalStatus ==1 && this.platformSignalVaule == ''){
        flag = false;
      }
      return flag;
    },
    platformSignalStatusFlag(){
      let flag = false;
      if(this.form.platformSignalStatus ==1){
        flag = true;
      } else if (this.form.platformSignalStatus ==0){
        flag = false;
      }
      return flag;
    },
    platformCopyFlag(){
      let flag = true;
      if(this.form.platformCopyStatus ==1 && this.platformCopyVaule == ''){
        flag = false;
      }
      return flag;
    },
    platformCopyStatusFlag(){
      let flag = false;
      if(this.form.platformCopyStatus ==1){
        flag = true;
      } else if (this.form.platformCopyStatus ==0){
        flag = false;
      }
      return flag;
    },
  },
  methods: {
    uniquesArr,
    getLimitName () {
      if(this.form.siteId) {
         var parmas = {
          siteId: this.form.siteId
        }
        findLimit(parmas).then(res => {
          var limitList = res
          let arrList = [];
          for (var i =0;i<limitList.length;i++) {
            var j = {};
            j.label = limitList[i]
            j.value = i+1
            arrList.push(j)
          }
        this.limitOptions = arrList
        }).catch(err => {

        })
      }
    },
    siteChange(e) {
      this.limitVaule = ''
      this.brokerList = ''
      this.getLimitName();
    },
    cancel() {
      this.resetForm()
      this.dialog = false
    },
    doSubmit() {
      this.form.ruleFees = this.uniquesArr(this.form.ruleFees);
      this.$refs["form"].validate(valid => {
        if (valid && this.platformSignalFlag && this.platformCopyFlag) {
          this.loading = true;
          const _this = this;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        } else {
          return false;
        }
      });
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm();
        this.dialog = false;
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
      })
    },
    doEdit() {
      this.loading = false
      this.form.ruleFees.forEach((item, index) => {
        if(item.price === ''){
          this.form.ruleFees.splice(index, 1)
        }
      })
      edit(this.form).then(res => {
        this.resetForm();
        this.dialog = false;
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.platformCopyVaule = [];
      this.platformSignalVaule = [];
      this.form = {
        freePeriod: '',
        platformCopy: '',
        platformSignal: '',
        platformSignalStatus: 0,
        platformCopyStatus: 0,
        ruleFees: [
          {
            price: 0,
            model: 1,
            currency: 'USD',
            status: 1,
            indexList: [],
          },
           {
            price: -1,
            model: 1,
            currency: 'USD',
            status: 0,
            indexList: [{indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
            {indexId:7,indexName:"最大回撤比例",indexLimit:">=",indexValue:0,},
            {indexId:8,indexName:"收益率",indexLimit:">=",indexValue:0,},
            {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
          },
        ],
        ruleHists: [],
        siteId: null,
        sitePriceList: null,
        status: 1
      }
    },
    addCharge() {
       this.ruleFeesArr.push({
          price: '',
          status: 1,
          model: 1,
          currency: 'USD',
          indexList: [ {indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
            {indexId:7,indexName:"最大回撤比例",indexLimit:"=",indexValue:0,},
            {indexId:8,indexName:"收益率",indexLimit:"=",indexValue:0,},
            {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
        });
    },
    SignalCheckChange(e) {
      if(e) {
        this.form.platformSignalStatus = 1
      } else {
        this.form.platformSignalStatus = 0
      }
    },
    CopyCheckChange(e) {
      if(e) {
        this.form.platformCopyStatus = 1
      } else {
        this.form.platformCopyStatus = 0
      }
    },
    brokerSignal(e) {
      this.form.platformSignal = e.join(',')
    },
    brokerCopy(e) {
      this.form.platformCopy = e.join(',')
    },
    // 信号限制弹出
    limitBoxClick(item,index,rows) {
      this.limitFrom.limitData = [];
      this.limitVisible = true
      this.limitIndex = index
      this.limitFrom.limitData = item.indexList
    },
    customlimitClick(row) {
      this.limitFrom.limitData = [];
      this.limitVisible = true;
      this.limitFrom.limitData = row;
    },
    //价格改变
    customChange(e,rows,index) {
      if(e) {
        this.customStatus = 1
      } else {
        this.customStatus = 0
      }
      rows.forEach((item, index) => {
        if(item.price< 0) {
          item.status = this.customStatus;
        }
      })
    },
    //价格改变
    checkChange(e,rows,index) {
      if(e) {
         rows[index].status = 1
      } else {
        rows[index].status = 0
      }
    },
    removelimitBox(item,index,row,rows) {
       row.splice(index, 1);
       var current = rows.indexOf(item);
       rows.splice(current, 1);
    },
    limitConfirm() {
      this.$refs["limitFrom"].validate(valid => {
        if (valid) {
          this.limitVisible = false;
          // for (var i = 0; i < this.form.ruleFees.length; i++) {
          //   this.form.ruleFees[this.limitIndex].indexList = this.limitFrom.limitData
          // }
        }
      });
    },
    getCate() {
      var ruleFeesArr = [];
      this.form.ruleFees.forEach((item, index) => {
        if(item.price< 0) {
          this.customStatus = item.status;
        }
        if(item.price>0){
          ruleFeesArr.push(item);
          // this.form.ruleFees.splice(index,1);
        }
        if(item.price <0) {
          this.customIndexList = item.indexList;
        }
      })
      if(ruleFeesArr.length<1) {
        ruleFeesArr = [{price: '',model: 1,currency: 'USD',status: 1,
        indexList: [{indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
            {indexId:7,indexName:"最大回撤比例",indexLimit:">=",indexValue:0,},
            {indexId:8,indexName:"收益率",indexLimit:">=",indexValue:0,},
            {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
        }]
      }
      ruleFeesArr.map( item => {
        item.itemFlag = false;
      });
      this.ruleFeesArr = ruleFeesArr;
//       getCate().then(res => {
//         this.ruleFeesListOne = []
//         this.ruleFeesListOne = res.data
//         this.ruleFeesListOne.forEach((item,index) =>{
//           item.indexValue = 0
//           item.indexLimit= '>='
//         })
//         if(this.isAdd) {
//           this.form.ruleFees[1].indexList = [ {indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
//             {indexId:7,indexName:"最大回撤比例",indexLimit:"=",indexValue:0,},
//             {indexId:8,indexName:"收益率",indexLimit:"=",indexValue:0,},
//             {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
//           this.form.ruleFees[2].indexList = [{indexId:12,indexName:"净值",indexLimit:">=",indexValue:0,},
//             {indexId:7,indexName:"最大回撤比例",indexLimit:"=",indexValue:0,},
//             {indexId:8,indexName:"收益率",indexLimit:"=",indexValue:0,},
//             {indexId:9,indexName:"交易周期",indexLimit:">=",indexValue:0,}]
//         }
//       }).catch(err => {
//         this.loading = false
//       })
    },
    indexValueChange(e) {
      this.$forceUpdate();
    },
    selectChange(e) {
      this.$forceUpdate();
    }
  }
}
</script>
<style lang="less">
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
</style>
<style lang="less" scoped>
  .el-bottom, .el-bottom .el-col{
    margin-bottom: 10px;
   }
  .el-row-bottom {
    margin-bottom: 20px;
  }
  .checkInput {
    width: 180px;
  }
  .LimitBox{
    margin-top: 20px;
    .LimitBottom {
      margin-bottom: 10px;
    }
  }
  .modifyDesc {
    border-top: 1px solid #333333;
    .title {
      font-size: 24px;
      color: #333;
      padding: 20px;
      font-weight: 500;
    }
    .from-bttom-desc {
      text-align: center;
    }
    .th {
      margin-bottom: 10px;
      line-height: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #333;
    }
  }
</style>
